import classNames from 'classnames';
import { RelLink } from '../../rel-link';
import { ResponseMenuItem } from '@/src/interfaces/data-contracts';
import Image from 'next/image';
import printBadgeType from '@/src/utils/helpers/badge-type';

type Props = {
  subItem: ResponseMenuItem;
  onSubMenuItemClick?: (...args: any) => void;
};

export const NavSubItem: React.FC<Props> = ({ subItem, onSubMenuItemClick }) => {
  return (
    <li className={classNames('inner bg-white')}>
      {subItem.url && (
        <RelLink
          onClick={onSubMenuItemClick}
          className={classNames('content flex items-center gap-2', {
            ['pointer-events-none']: (subItem.badgeType as unknown as number) === 30,
          })}
          title={subItem.name ?? ''}
          href={subItem.url}
        >
          {subItem.imagePath && (
            <Image
              src={subItem.imagePath}
              alt={subItem?.name!}
              width={24}
              height={24}
              className={classNames('icon h-6 w-6')}
            />
          )}
          <span
            className={classNames('title whitespace-nowrap text-sm font-semibold text-black900', {
              ['opacity-50']: (subItem.badgeType as unknown as number) === 30,
            })}
          >
            {subItem.name}
          </span>
          {subItem.badgeType && (
            <span
              className={classNames(
                { 'bg-introLight text-intro': 10 === (subItem.badgeType as unknown as number) },
                { 'bg-purple50 text-purple404': 20 === (subItem.badgeType as unknown as number) },
                { 'bg-orange50 text-orange500': 30 === (subItem.badgeType as unknown as number) },
                'badge rounded p-1 text-xs font-semibold'
              )}
            >
              {printBadgeType[subItem.badgeType]}
            </span>
          )}
        </RelLink>
      )}
    </li>
  );
};
