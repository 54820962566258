'use client';

import Footer from '@/src/features/ui/footer';
import FooterSimple from '@/src/features/ui/footer/footer-simple';
import { OriginalLocationContext } from '@/src/store/contexts/original-location-context';
import { usePathname } from 'next/navigation';
import React, { Suspense, useEffect, useState } from 'react';
import { Provider } from 'react-redux';
import FullSkeleton from '../features/ui/full-skeleton/full-skeleton';
import Header from '../features/ui/header';
import { UserAgent } from '../interfaces/missing-types';
import store from '../store';
import { DeviceContext } from '../store/contexts/device-context';
import { ResponseMenuItemList } from '@/src/interfaces/data-contracts';
import { Logo } from '../features/ui/icons/icons';
// import HelloBar from '../features/hellobar';
import Link from 'next/link';

type Props = {
  children: React.ReactNode;
  userAgent: UserAgent;
  menuList: ResponseMenuItemList;
};

// const DynamicFooter = dynamic(() => import('../features/ui/footer'));
// const DynamicFooterSimple = dynamic(() => import('../features/ui/footer/footer-simple'));

export const LayoutClient: React.FC<Props> = ({ children, userAgent, menuList }) => {
  const pathname = usePathname() ?? '';
  const [originalUrl, setOriginalUrl] = useState<string | null>(null);
  const isBankIntegration = pathname.includes('int/basvuru/form');
  const containsRedirect = /(?<!success\/)yonlendirme/g.test(pathname);
  const { headerItems, hamburgerItems, footerItems } = menuList;
  const containsLoginorForgotPassword =
    pathname?.includes('/giris') || pathname?.includes('/parola-unuttum') || pathname?.includes('/parola-guncelle');

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  useEffect(() => {
    if (!originalUrl) {
      setOriginalUrl(window.location.href);
    }
  }, []);

  useEffect(() => {
    if ('serviceWorker' in navigator) {
      window.addEventListener('load', () => {
        navigator.serviceWorker
          .register('/relatedpush_sw.js')
          .then((registration) => {
            // console.log('Service worker relatedpush registered:', registration);
          })
          .catch((error) => {
            console.warn('Service worker relatedpush registration failed:', error);
          });
      });
    }
  }, []);

  if (containsRedirect && !isBankIntegration) {
    return (
      <Provider store={store}>
        <DeviceContext.Provider value={userAgent}>
          <OriginalLocationContext.Provider value={{ originalUrl }}>
            {/* footerin ekranin sonuna inmesi isteniyorsa main flex-1 verilmeli */}
            <main className={`block w-full ${isBankIntegration ? 'flex-1' : ''}`}>{children}</main>
            <Suspense fallback={<FullSkeleton />}>
              {/*<DynamicFooterSimple />*/}
              {isBankIntegration && footerItems ? (
                <Footer footerItems={footerItems} hideTop={isBankIntegration} />
              ) : (
                <FooterSimple />
              )}
            </Suspense>
          </OriginalLocationContext.Provider>
        </DeviceContext.Provider>
      </Provider>
    );
  }
  if (containsLoginorForgotPassword) {
    return (
      <Provider store={store}>
        <DeviceContext.Provider value={userAgent}>
          <OriginalLocationContext.Provider value={{ originalUrl }}>
            <div className="mt-3 flex flex-col items-center gap-9 md:mt-[42px] md:gap-[46px]">
              <Link href={'/'}>
                <Logo width={127} height={36} />
              </Link>
              <main className="flex w-full items-center justify-center">{children}</main>
            </div>
          </OriginalLocationContext.Provider>
        </DeviceContext.Provider>
      </Provider>
    );
  }
  return (
    <Provider store={store}>
      <DeviceContext.Provider value={userAgent}>
        <OriginalLocationContext.Provider value={{ originalUrl }}>
          <Suspense fallback={<FullSkeleton />}>
            {!(isBankIntegration && containsRedirect) && headerItems && hamburgerItems && (
              <Header headerItems={headerItems} hamburgerItems={hamburgerItems} />
            )}
          </Suspense>
          <main className="block w-full flex-1">{children}</main>
          <Suspense fallback={<FullSkeleton />}>
            {/*<DynamicFooter />*/}
            {footerItems && <Footer footerItems={footerItems} hideTop={isBankIntegration} />}
          </Suspense>
        </OriginalLocationContext.Provider>
      </DeviceContext.Provider>
    </Provider>
  );
};
